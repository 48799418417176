@keyframes marquee_marquee__V1v8g {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes marquee_marquee-reverse__hQZvM {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
}

.marquee_animate-marquee__5aRjx {
  animation: marquee_marquee__V1v8g 10s linear infinite;
  animation-direction: var(--animation-marquee-direction);
}

.marquee_animate-marquee-reverse__Lx_IT {
  animation: marquee_marquee-reverse__hQZvM 10s linear infinite;
  animation-name: marquee_marquee-reverse__hQZvM;

  animation-direction: var(--marquee-direction);
}

/* cyrillic-ext */
@font-face {
  font-family: '__Sofia_Sans_Condensed_d6229b';
  font-style: normal;
  font-weight: 1 1000;
  font-display: swap;
  src: url(/_next/static/media/4154320d51e66c1d-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Sofia_Sans_Condensed_d6229b';
  font-style: normal;
  font-weight: 1 1000;
  font-display: swap;
  src: url(/_next/static/media/1ab5c95b675342ca-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: '__Sofia_Sans_Condensed_d6229b';
  font-style: normal;
  font-weight: 1 1000;
  font-display: swap;
  src: url(/_next/static/media/41a2c06f3dc6697d-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* latin-ext */
@font-face {
  font-family: '__Sofia_Sans_Condensed_d6229b';
  font-style: normal;
  font-weight: 1 1000;
  font-display: swap;
  src: url(/_next/static/media/7dc8256c3326c918-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Sofia_Sans_Condensed_d6229b';
  font-style: normal;
  font-weight: 1 1000;
  font-display: swap;
  src: url(/_next/static/media/d13f72fdd4ba57a5-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Sofia_Sans_Condensed_Fallback_d6229b';src: local("Arial");ascent-override: 120.13%;descent-override: 40.04%;line-gap-override: 0.00%;size-adjust: 74.92%
}.__className_d6229b {font-family: '__Sofia_Sans_Condensed_d6229b', '__Sofia_Sans_Condensed_Fallback_d6229b';font-style: normal
}

/* cyrillic-ext */
@font-face {
  font-family: '__Sofia_Sans_Condensed_d6229b';
  font-style: normal;
  font-weight: 1 1000;
  font-display: swap;
  src: url(/_next/static/media/4154320d51e66c1d-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Sofia_Sans_Condensed_d6229b';
  font-style: normal;
  font-weight: 1 1000;
  font-display: swap;
  src: url(/_next/static/media/1ab5c95b675342ca-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: '__Sofia_Sans_Condensed_d6229b';
  font-style: normal;
  font-weight: 1 1000;
  font-display: swap;
  src: url(/_next/static/media/41a2c06f3dc6697d-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* latin-ext */
@font-face {
  font-family: '__Sofia_Sans_Condensed_d6229b';
  font-style: normal;
  font-weight: 1 1000;
  font-display: swap;
  src: url(/_next/static/media/7dc8256c3326c918-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Sofia_Sans_Condensed_d6229b';
  font-style: normal;
  font-weight: 1 1000;
  font-display: swap;
  src: url(/_next/static/media/d13f72fdd4ba57a5-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Sofia_Sans_Condensed_Fallback_d6229b';src: local("Arial");ascent-override: 120.13%;descent-override: 40.04%;line-gap-override: 0.00%;size-adjust: 74.92%
}.__className_d6229b {font-family: '__Sofia_Sans_Condensed_d6229b', '__Sofia_Sans_Condensed_Fallback_d6229b';font-style: normal
}

/* cyrillic-ext */
@font-face {
  font-family: '__Sofia_Sans_Condensed_d6229b';
  font-style: normal;
  font-weight: 1 1000;
  font-display: swap;
  src: url(/_next/static/media/4154320d51e66c1d-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Sofia_Sans_Condensed_d6229b';
  font-style: normal;
  font-weight: 1 1000;
  font-display: swap;
  src: url(/_next/static/media/1ab5c95b675342ca-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: '__Sofia_Sans_Condensed_d6229b';
  font-style: normal;
  font-weight: 1 1000;
  font-display: swap;
  src: url(/_next/static/media/41a2c06f3dc6697d-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* latin-ext */
@font-face {
  font-family: '__Sofia_Sans_Condensed_d6229b';
  font-style: normal;
  font-weight: 1 1000;
  font-display: swap;
  src: url(/_next/static/media/7dc8256c3326c918-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Sofia_Sans_Condensed_d6229b';
  font-style: normal;
  font-weight: 1 1000;
  font-display: swap;
  src: url(/_next/static/media/d13f72fdd4ba57a5-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Sofia_Sans_Condensed_Fallback_d6229b';src: local("Arial");ascent-override: 120.13%;descent-override: 40.04%;line-gap-override: 0.00%;size-adjust: 74.92%
}.__className_d6229b {font-family: '__Sofia_Sans_Condensed_d6229b', '__Sofia_Sans_Condensed_Fallback_d6229b';font-style: normal
}

/* cyrillic-ext */
@font-face {
  font-family: '__Sofia_Sans_Condensed_d6229b';
  font-style: normal;
  font-weight: 1 1000;
  font-display: swap;
  src: url(/_next/static/media/4154320d51e66c1d-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Sofia_Sans_Condensed_d6229b';
  font-style: normal;
  font-weight: 1 1000;
  font-display: swap;
  src: url(/_next/static/media/1ab5c95b675342ca-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: '__Sofia_Sans_Condensed_d6229b';
  font-style: normal;
  font-weight: 1 1000;
  font-display: swap;
  src: url(/_next/static/media/41a2c06f3dc6697d-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* latin-ext */
@font-face {
  font-family: '__Sofia_Sans_Condensed_d6229b';
  font-style: normal;
  font-weight: 1 1000;
  font-display: swap;
  src: url(/_next/static/media/7dc8256c3326c918-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Sofia_Sans_Condensed_d6229b';
  font-style: normal;
  font-weight: 1 1000;
  font-display: swap;
  src: url(/_next/static/media/d13f72fdd4ba57a5-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Sofia_Sans_Condensed_Fallback_d6229b';src: local("Arial");ascent-override: 120.13%;descent-override: 40.04%;line-gap-override: 0.00%;size-adjust: 74.92%
}.__className_d6229b {font-family: '__Sofia_Sans_Condensed_d6229b', '__Sofia_Sans_Condensed_Fallback_d6229b';font-style: normal
}

/* cyrillic-ext */
@font-face {
  font-family: '__Sofia_Sans_Extra_Condensed_a209e5';
  font-style: normal;
  font-weight: 1 1000;
  font-display: swap;
  src: url(/_next/static/media/73fd0cb1ed7422f7-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Sofia_Sans_Extra_Condensed_a209e5';
  font-style: normal;
  font-weight: 1 1000;
  font-display: swap;
  src: url(/_next/static/media/f5e38796ef4327c2-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: '__Sofia_Sans_Extra_Condensed_a209e5';
  font-style: normal;
  font-weight: 1 1000;
  font-display: swap;
  src: url(/_next/static/media/c29125273e959bc3-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* latin-ext */
@font-face {
  font-family: '__Sofia_Sans_Extra_Condensed_a209e5';
  font-style: normal;
  font-weight: 1 1000;
  font-display: swap;
  src: url(/_next/static/media/8acdecaabe9eb28b-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Sofia_Sans_Extra_Condensed_a209e5';
  font-style: normal;
  font-weight: 1 1000;
  font-display: swap;
  src: url(/_next/static/media/76e2b6d6af500966-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Sofia_Sans_Extra_Condensed_Fallback_a209e5';src: local("Arial");ascent-override: 147.51%;descent-override: 49.17%;line-gap-override: 0.00%;size-adjust: 61.01%
}.__className_a209e5 {font-family: '__Sofia_Sans_Extra_Condensed_a209e5', '__Sofia_Sans_Extra_Condensed_Fallback_a209e5';font-style: normal
}

/* cyrillic-ext */
@font-face {
  font-family: '__Sofia_Sans_Condensed_d6229b';
  font-style: normal;
  font-weight: 1 1000;
  font-display: swap;
  src: url(/_next/static/media/4154320d51e66c1d-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Sofia_Sans_Condensed_d6229b';
  font-style: normal;
  font-weight: 1 1000;
  font-display: swap;
  src: url(/_next/static/media/1ab5c95b675342ca-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: '__Sofia_Sans_Condensed_d6229b';
  font-style: normal;
  font-weight: 1 1000;
  font-display: swap;
  src: url(/_next/static/media/41a2c06f3dc6697d-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* latin-ext */
@font-face {
  font-family: '__Sofia_Sans_Condensed_d6229b';
  font-style: normal;
  font-weight: 1 1000;
  font-display: swap;
  src: url(/_next/static/media/7dc8256c3326c918-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Sofia_Sans_Condensed_d6229b';
  font-style: normal;
  font-weight: 1 1000;
  font-display: swap;
  src: url(/_next/static/media/d13f72fdd4ba57a5-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Sofia_Sans_Condensed_Fallback_d6229b';src: local("Arial");ascent-override: 120.13%;descent-override: 40.04%;line-gap-override: 0.00%;size-adjust: 74.92%
}.__className_d6229b {font-family: '__Sofia_Sans_Condensed_d6229b', '__Sofia_Sans_Condensed_Fallback_d6229b';font-style: normal
}

/* cyrillic-ext */
@font-face {
  font-family: '__Sofia_Sans_Extra_Condensed_a209e5';
  font-style: normal;
  font-weight: 1 1000;
  font-display: swap;
  src: url(/_next/static/media/73fd0cb1ed7422f7-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Sofia_Sans_Extra_Condensed_a209e5';
  font-style: normal;
  font-weight: 1 1000;
  font-display: swap;
  src: url(/_next/static/media/f5e38796ef4327c2-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: '__Sofia_Sans_Extra_Condensed_a209e5';
  font-style: normal;
  font-weight: 1 1000;
  font-display: swap;
  src: url(/_next/static/media/c29125273e959bc3-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* latin-ext */
@font-face {
  font-family: '__Sofia_Sans_Extra_Condensed_a209e5';
  font-style: normal;
  font-weight: 1 1000;
  font-display: swap;
  src: url(/_next/static/media/8acdecaabe9eb28b-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Sofia_Sans_Extra_Condensed_a209e5';
  font-style: normal;
  font-weight: 1 1000;
  font-display: swap;
  src: url(/_next/static/media/76e2b6d6af500966-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Sofia_Sans_Extra_Condensed_Fallback_a209e5';src: local("Arial");ascent-override: 147.51%;descent-override: 49.17%;line-gap-override: 0.00%;size-adjust: 61.01%
}.__className_a209e5 {font-family: '__Sofia_Sans_Extra_Condensed_a209e5', '__Sofia_Sans_Extra_Condensed_Fallback_a209e5';font-style: normal
}

/* cyrillic-ext */
@font-face {
  font-family: '__Sofia_Sans_Extra_Condensed_a209e5';
  font-style: normal;
  font-weight: 1 1000;
  font-display: swap;
  src: url(/_next/static/media/73fd0cb1ed7422f7-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Sofia_Sans_Extra_Condensed_a209e5';
  font-style: normal;
  font-weight: 1 1000;
  font-display: swap;
  src: url(/_next/static/media/f5e38796ef4327c2-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: '__Sofia_Sans_Extra_Condensed_a209e5';
  font-style: normal;
  font-weight: 1 1000;
  font-display: swap;
  src: url(/_next/static/media/c29125273e959bc3-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* latin-ext */
@font-face {
  font-family: '__Sofia_Sans_Extra_Condensed_a209e5';
  font-style: normal;
  font-weight: 1 1000;
  font-display: swap;
  src: url(/_next/static/media/8acdecaabe9eb28b-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Sofia_Sans_Extra_Condensed_a209e5';
  font-style: normal;
  font-weight: 1 1000;
  font-display: swap;
  src: url(/_next/static/media/76e2b6d6af500966-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Sofia_Sans_Extra_Condensed_Fallback_a209e5';src: local("Arial");ascent-override: 147.51%;descent-override: 49.17%;line-gap-override: 0.00%;size-adjust: 61.01%
}.__className_a209e5 {font-family: '__Sofia_Sans_Extra_Condensed_a209e5', '__Sofia_Sans_Extra_Condensed_Fallback_a209e5';font-style: normal
}

