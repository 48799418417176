@keyframes marquee {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes marquee-reverse {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
}

.animate-marquee {
  animation: marquee 10s linear infinite;
  animation-direction: var(--animation-marquee-direction);
}

.animate-marquee-reverse {
  animation: marquee-reverse 10s linear infinite;
  animation-name: marquee-reverse;

  animation-direction: var(--marquee-direction);
}
